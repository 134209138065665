
import { Component, Inject, Vue } from 'vue-property-decorator';

import AlertService from '@/shared/alert/alert.service';
import TextFieldSettingService from '@/entities/text-field-setting/text-field-settings.service';
import { ITextFieldSetting, TextFieldType } from '@/shared/model/text-field-setting.model';
import { text } from '@fortawesome/fontawesome-svg-core';

@Component({
  methods: { text },
})
export default class TextSettings extends Vue {
  public isSaving = false;

  @Inject('alertService') public alertService: () => AlertService;
  @Inject('textFieldSettingService')
  public textFieldSettingService: () => TextFieldSettingService;

  public isLoading = false;
  public textSettings: ITextFieldSetting[] = [];

  public async mounted(): Promise<void> {
    await this.init();
  }
  public translateType(key: TextFieldType) {
    return this.textFieldSettingService().getDisplayString(key);
  }

  checkIfDeprecated(key: TextFieldType) {
    return this.textFieldSettingService().checkIfDeprecated(key);
  }
  /**
   * saves and updates all percentages in list
   */
  async saveAllTextFields() {
    if (!this.textSettings || this.textSettings.length === 0) {
      return;
    }
    try {
      await this.textFieldSettingService().saveAll(this.textSettings);
      this.alertService().showSuccess(this, 'Die Einträge wurden erfolgreich gespeichert.');
      await this.init();
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Speichern der Einträge');
    }
  }

  private async init() {
    this.isLoading = true;
    try {
      const res = await this.textFieldSettingService().retrieve({
        page: 0,
        size: 1000,
        sort: [],
      });
      const unsorted = res.data;
      this.textSettings = this.textFieldSettingService().sortTextSettings(unsorted);
    } catch (e) {
      this.alertService().showError(this, 'Beim Laden der Einträge ist ein Fehler aufgetreten');
    }
    this.isLoading = false;
  }
}
