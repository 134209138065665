var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "mt-3 p-2" }, [
    _c(
      "form",
      {
        attrs: { name: "editForm", novalidate: "", role: "form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.saveAllTextFields()
          },
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("div", [
              _vm.textSettings && _vm.textSettings.length > 0
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _vm._l(_vm.textSettings, function (textSetting, index) {
                        return _c(
                          "div",
                          {
                            key: index + "textSetting",
                            staticClass: "align-items-start mt-3",
                          },
                          [
                            textSetting &&
                            !_vm.checkIfDeprecated(textSetting.type)
                              ? _c("div", { staticClass: "row col mb-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group mb-0",
                                      staticStyle: { flex: "1" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translateType(
                                                textSetting.type
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: textSetting.value,
                                            expression: "textSetting.value",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "text-" + index,
                                          name: "text-" + index,
                                          placeholder: _vm.translateType(
                                            textSetting.type
                                          ),
                                          rows: "5",
                                        },
                                        domProps: { value: textSetting.value },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              textSetting,
                                              "value",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "action-wrapper text-right mt-3" },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { id: "save-entity", type: "submit" },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "save" },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                Änderungen Speichern"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.textSettings.length === 0
                ? _c("div", { staticClass: "mt-3 alert alert-secondary" }, [
                    _vm._v(
                      "\n        Es sind keine Textbaustein-Einstellungen im System hinterlegt.\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.isLoading
      ? _c(
          "div",
          [
            _c("font-awesome-icon", {
              staticClass: "m-3",
              attrs: { spin: true, icon: "sync" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("Textbausteine")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }